/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCuwpPLvp0vuz8jj2osy0K99BkaJKEOT3w",
  "appId": "1:463294840770:web:3c78560286e5c7779ba05b",
  "authDomain": "schooldog-i-dawson-ga.firebaseapp.com",
  "measurementId": "G-63ETXB8F99",
  "messagingSenderId": "463294840770",
  "project": "schooldog-i-dawson-ga",
  "projectId": "schooldog-i-dawson-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-dawson-ga.appspot.com"
}
